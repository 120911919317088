<template>
  <async-container :loading="!loaded">
    <v-data-table
      class="elevation-2"
      :items="itemsFiltered"
      :headers="headers"
      :search="search"
      sort-by="nome"
      :footer-props="{'items-per-page-options': [10, 25, 50, 100]}"
    >
      <template v-slot:top>
        <v-card-title class="title-bar">
          Delegados
          <v-spacer/>
          <v-btn color="primary" @click="() => {limparCampos(); showDialogAdd = true;}">ADICIONAR</v-btn>
        </v-card-title>
        <v-card-text class="pb-0">
          <v-row>
            <v-col cols="12">
              <v-text-field
                class="pt-0"
                v-model="search"
                prepend-inner-icon="mdi-magnify"
                label="Busque por nome ou CRECI"
                single-line
                hide-details
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-autocomplete
                label="Cidade"
                :items="[{text: 'Todas', value: null}, ...cidadesDoMs.map(c => ({text: c, value: c}))]"
                v-model="filtroCidade"
                outlined
                dense
                persistent-hint
                hint="Clique e digite o nome da cidade para filtrar"
                no-data-text="Nada encontrado"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6">
              <v-select
                label="Categoria"
                :items="[{text: 'Todas', value: null},...categorias]"
                v-model="filtroCategoria"
                outlined
                dense
                hide-details
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>
      </template>
      <template v-slot:item.categoria="{item}">
        <v-chip color="success" small label v-if="item.categoria === 0">Setorial</v-chip>
        <v-chip color="warning" small label v-else-if="item.categoria === 1">Municipal</v-chip>
        <v-chip color="primary" small label v-else-if="item.categoria === 2">Regional</v-chip>
      </template>
      <template v-slot:item.actions="{item}">
        <div class="text-no-wrap">
          <v-btn icon x-small color="warning" @click="editarDelegado(item.id)">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn icon x-small color="error" @click="deletarDelegado(item.id)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>
    <v-dialog v-model="showDialogAdd" max-width="500">
      <v-card>
        <v-card-title>{{iptId ? 'Editar' : 'Adicionar'}} delegado</v-card-title>
        <v-card-text>
          <v-form v-model="formAddValid">
            <v-text-field
              label="Número de CRECI"
              v-model="iptCreci"
              :rules="[rules.obrigatorio, rules.numerico, ruleDuplicated]"
              :disabled="loadingAdd"
            ></v-text-field>
            <v-select
              label="Categoria"
              :items="categorias"
              v-model="iptCategoria"
              :disabled="loadingAdd"
              hide-details
              class="my-3"
            ></v-select>
            <v-autocomplete
              label="Cidade"
              :items="[{text: 'Todas', value: null}, ...cidadesDoMs.map(c => ({text: c, value: c.toUpperCase()}))]"
              v-model="iptCidade"
              hint="Clique e digite o nome da cidade para filtrar"
              no-data-text="Nada encontrado"
              :disabled="loadingAdd"
              :rules="[rules.obrigatorio]"
            ></v-autocomplete>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn text color="primary" @click="showDialogAdd = false" :disabled="loadingAdd">Cancelar</v-btn>
          <v-btn text color="primary" @click="adicionarDelegado" :disabled="!formAddValid" :loading="loadingAdd">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showDeleteDialog" max-width="500">
      <v-card>
        <v-card-title style="background-color: #f5f5f5; border-bottom: 1px solid #dbdbdb">Excluir delegado</v-card-title>
        <v-card-text class="mt-3">
          <div class="d-flex align-center">
            <v-icon size="48" class="mr-3" color="error">mdi-alert</v-icon>
            <p class="body-1 flex-grow-1 mb-0">Você está prestes a remover o título de delegado de:<br/><span class="font-weight-bold">"{{deleteName}}"</span></p>
          </div>
        </v-card-text>
        <v-card-actions style="background-color: #f5f5f5; border-top: 1px solid #dbdbdb">
          <v-spacer></v-spacer>
          <v-btn color="secondary" @click="showDeleteDialog = false" :disabled="deleting">CANCELAR</v-btn>
          <v-btn color="error" @click="deletarDelegado()" :loading="deleting">CONFIRMAR</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </async-container>
</template>

<script>
import AsyncContainer from '@/components/interface/AsyncContainer';
import DiretoriaWebClient from '@/http/DiretoriaWebClient';
import I2brWebClient from '@/http/I2brWebClient';
import {cidadesDoMs} from '@/helpers/AppData'
import StringHelper from '@/helpers/StringHelper';
export default {
  name: 'Delegados',
  components: {AsyncContainer},
  data: () => ({
    loaded: false,
    items: [],
    headers: [
      {text: 'Nome', value: 'nome'},
      {text: 'CRECI', value: 'creci', align: 'center'},
      {text: 'Cidade', value: 'cidade', filterable: false},
      {text: 'Categoria', value: 'categoria', align: 'center', filterable: false},
      {text: 'Ações', value: 'actions', align: 'center', filterable: false, sortable: false},
    ],
    search: '',
    webClient: new DiretoriaWebClient(),
    cidadesDoMs,
    filtroCidade: null,
    filtroCategoria: null,
    categorias: [
      {text: 'Setorial', value: 0},
      {text: 'Municipal', value: 1},
      {text: 'Regional', value: 2},
    ],
    showDialogAdd: false,
    //Inputs do form Add
    iptId: null,
    iptCreci: '',
    iptCategoria: 0,
    iptCidade: '',
    rules: {
      obrigatorio: v => !!v || 'Este dado é obrigatório',
      numerico: v => (v && StringHelper.extractNumbers(v).length === v.trim().length) || 'Insira apenas números',
    },
    formAddValid: false,
    loadingAdd: false,
    deleteId: null,
    deleteName: '',
    showDeleteDialog: false,
    deleting: false,
  }),
  methods: {
    async loadData() {
      try {
        this.items = await this.webClient.getDelegados();
      } catch (e) {
        this.$router.push('/diretoria');
      } finally {
        this.loaded = true;
      }
    },
    async adicionarDelegado() {
      if (!this.formAddValid) return;
      this.loadingAdd = true;
      try {
        const i2brWebClient = new I2brWebClient();
        const result = await i2brWebClient.buscarCreci(this.iptCreci);
        const corretor = result.find(i => i.tipo === 1);
        if (!corretor) {
          this.$alert({text: 'Não foi possível encontrar o corretor pelo ConselhoNET', type: 'error'});
          return;
        }
        if (this.iptId) {
          await this.webClient.updateDelegado(this.iptId, corretor.nome, this.iptCreci, this.iptCategoria, this.iptCidade);
          this.$snackbar({text: 'Delegado atualizado', color: 'success'});
        } else {
          await this.webClient.insertDelegado(corretor.nome, this.iptCreci, this.iptCategoria, this.iptCidade);
          this.$snackbar({text: `Delegado inserido: ${corretor.nome}`, color: 'success'});
        }
        await this.loadData();
        this.showDialogAdd = false;
      } finally {
        this.loadingAdd = false;
      }
    },
    ruleDuplicated(v) {
      if (this.iptId) return true;
      const creci = v ? parseInt(v) : 0;
      return !this.items.find(i => i.creci === creci) || 'Este CRECI já está na lista';
    },
    async deletarDelegado(id) {
      if (!id && this.deleteId) {
        this.deleting = true;
        try {
          await this.webClient.deleteDelegado(this.deleteId);
          await this.loadData();
        } finally {
          this.deleting = false;
          this.showDeleteDialog = false;
        }
      } else {
        this.deleteId = id;
        this.deleteName = this.items.find(d => d.id === id).nome;
        this.showDeleteDialog = true;
      }
    },
    editarDelegado(id) {
      this.limparCampos();
      const delegado = this.items.find(d => d.id === id);
      if (!delegado) return;
      this.iptId = id;
      this.iptCreci = delegado.creci.toString();
      this.iptCategoria = delegado.categoria;
      this.iptCidade = delegado.cidade.toUpperCase();
      this.showDialogAdd = true;
    },
    limparCampos() {
      this.iptId = null;
      this.iptCreci = '';
      this.iptCategoria = 0;
      this.iptCidade = '';
    },
  },
  created() {
    this.loadData();
  },
  computed: {
    itemsFiltered() {
      let items = this.items;
      if (this.filtroCidade !== null) items = items.filter(i => i.cidade.toUpperCase() === this.filtroCidade.toUpperCase());
      if (this.filtroCategoria !== null) items = items.filter(i => i.categoria === this.filtroCategoria);
      return items;
    },
  },
}
</script>

<style scoped>
  .title-bar {
    background-color: #f5f5f5;
    border-bottom: 1px solid #dbdbdb !important;
    padding: .75rem;
    margin-bottom: 0;
  }
</style>
