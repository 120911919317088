import { httpSnackbar, httpSilent } from '@/plugins/axios'

export default class I2brWebClient {
  http = httpSnackbar;

  /**
   * Construtor
   * @param {boolean} silent
   */
  constructor(silent = false) {
    if (silent) this.http = httpSilent;
  }

  /**
   * Obtem a ficha cadastral do corretor de imoveis.
   * @param {string} cpf - CPF do corretor de imoveis.
   * @returns {Promise<any|null>} - NULL caso não seja um corretor. False caso haja erro com a API.
   */
  async getFicha(cpf) {
    const formData = new FormData();
    formData.append('cpf', cpf);
    formData.append('idregional', '14');
    try {
      const response = await this.http.post('https://api.conselho.net.br/corretor/ficha.php', formData, { validateStatus: status => status === 200 || status === 460 });
      if (response.status === 200) {
        if (response.data.dados_gerais.creci === 0) return null;
        return response.data;
      }
      else if (response.status === 460) return null;
      else throw new Error();
    } catch (e) {
      return false;
    }
  }

  /**
   * Obtem multiplas fichas de uma vez, contem atributos extras que informam se o corretor possui foto e assintaura. É mais performatico que varias consultas singulares.
   * @param {array<string>} cpfs_crecis Array com CPFs ou Numeros de CRECIs (use o segundo param neste caso).
   * @param {boolean} creci O primeiro parametro são numeros de CRECIs.
   * @returns {Promise<boolean|any>} False em caso de falha.
   */
  async getFichas(cpfs_crecis, creci = false) {
    const formData = new FormData();
    formData.append('cpf', JSON.stringify(cpfs_crecis));
    formData.append('idregional', '14');
    formData.append('token', 'c8a026673da99f3dc18634dca15aec8a');
    if (creci) formData.append('creci', 'S');
    try {
      const response = await this.http.post('https://api.conselho.net.br/corretor/multificha.php', formData, {timeout: 180000});
      if (response.status === 200) return response.data;
      else throw new Error();
    } catch (e) {
      return false;
    }
  }

  /**
   * Busca corretores de imoveis atraves do numero de inscricao (CRECI).
   * @param {number} creci Numero de inscricao no CRECI.
   * @returns {Promise<boolean|array>} Array de resultados. False se falhar.
   */
  async buscarCreci(creci) {
    const formData = new FormData();
    formData.append('creci', creci.toString());
    formData.append('idregional', '14');
    try {
      const response = await this.http.post('https://api.conselho.net.br/corretor/buscar.php', formData);
      if (response.status === 200) return response.data;
      else return false;
    } catch (e) {
      return false;
    }
  }

  async getCidadesComCorretoresAtivos() {
    const formData = new FormData();
    formData.append('idregional', '14');
    const {data} = await this.http.post('https://api.conselho.net.br/regional/fiscalizacao/relatorios/cidades_com_ativos.php', formData);
    return data;
  }

  async getCertidao(cpf) {
    const {data} = await this.http.get(`/carp/certidao?cpf=${cpf}`);
    return data;
  }

  /**
   * Obtem a lista de cadastros que nao possuem foto ou assinatura.
   * @param {number} offset Pular os primeros X cadastros.
   * @param {number} limit Limite de resultados.
   * @returns {Promise<any[]>} Cadastros.
   */
  async getCadastrosSemFotoAss(offset, limit) {
    const formData = new FormData();
    formData.append('idregional', '14');
    formData.append('offset', offset.toString());
    formData.append('limit', limit.toString());
    const {data} = await this.http.post('https://api.conselho.net.br/corretor/relatorios/semimagem.php', formData);
    return data;
  }

  /**
   * Obtem apenas o numero de cadastros que podem ser varridos
   * @returns {Promise<number>}
   */
  async getCadastrosSemFotoAssQtd() {
    const {data} = await this.http.get('https://api.conselho.net.br/corretor/relatorios/semimagem.php?idregional=14');
    return data;
  }

  async getFoto(cpf) {
    const formData = new FormData();
    formData.append('cpf', cpf);
    formData.append('idregional', '14');
    const r = await this.http.post('https://api.conselho.net.br/corretor/obter/foto.php', formData, {responseType: 'blob', validateStatus: status => status === 200 || status === 404})
    if (r.status === 200) return r.data;
    else return null;
  }
}