import { httpSnackbar, httpSilent } from '@/plugins/axios'

export default class DiretoriaWebClient {
  http = httpSnackbar

  /**
   * Construtor
   * @param {boolean} silent
   */
  constructor(silent = false) {
    if (silent) this.http = httpSilent;
  }

  //=== Delegados ===//

  /**
   * Obtem a lista de delegados.
   * @returns {Promise<any[]>}
   */
  async getDelegados() {
    const {data} = await this.http.get('/diretoria/delegados');
    return data;
  }

  /**
   * Insere um delegado.
   * @param {string} nome Nome completo;
   * @param {number} creci Numero de CRECI.
   * @param {0|1|2} categoria Tipo de delegado. 0 = Setorial, 1 = Municipal, 2 = Regional.
   * @param {string} cidade Cidade onde atua.
   * @returns {Promise<number>} Retorna o ID.
   */
  async insertDelegado(nome, creci, categoria, cidade) {
    const formData = new FormData();
    formData.append('nome', nome);
    formData.append('creci', creci.toString());
    formData.append('categoria', categoria.toString());
    formData.append('cidade', cidade);
    const {data} = await this.http.post('/diretoria/delegados', formData);
    return data;
  }

  /**
   * Insere um delegado.
   * @param {number} id Chave primaria;
   * @param {string} nome Nome completo;
   * @param {number} creci Numero de CRECI.
   * @param {0|1|2} categoria Tipo de delegado. 0 = Setorial, 1 = Municipal, 2 = Regional.
   * @param {string} cidade Cidade onde atua.
   * @returns {Promise<number>}
   */
  async updateDelegado(id, nome, creci, categoria, cidade) {
    const formData = new FormData();
    formData.append('id', id.toString());
    formData.append('nome', nome);
    formData.append('creci', creci.toString());
    formData.append('categoria', categoria.toString());
    formData.append('cidade', cidade);
    const {data} = await this.http.post('/diretoria/delegados', formData);
    return data;
  }

  /**
   * Deleta um delegado.
   * @param {number} id Chave primaria.
   * @returns {Promise<void>}
   */
  async deleteDelegado(id) {
    await this.http.delete('/diretoria/delegados', {params: {id: id.toString()}});
  }

  //=== Conselheiros ===//

  /**
   * Obtem a lista de conselheiros.
   * @returns {Promise<any>}
   */
  async getConselheiros() {
    const {data} = await this.http.get('/diretoria/conselheiros');
    return data;
  }

  /**
   * Insere um Conselheiro.
   * @param {string} nome Nome completo.
   * @param {number} creci Numero de CRECI.
   * @param {boolean} suplente True = suplente. False = Efetivo.
   * @param {0|1|2} federal 0 = nao. 1 = efetivo. 2 = suplente.
   * @param {0|1|2} fiscal 0 = nao. 1 = efetivo. 2 = suplente.
   * @returns {Promise<number>} Retorna o ID.
   */
  async insertConselheiro(nome, creci, suplente, federal, fiscal) {
    const formData = new FormData();
    formData.append('nome', nome);
    formData.append('creci', creci.toString());
    formData.append('suplente', suplente ? '1' : '0');
    formData.append('federal', federal.toString());
    formData.append('fiscal', fiscal.toString());
    const {data} = await this.http.post('/diretoria/conselheiros', formData);
    return data;
  }

  /**
   * Atualiza um Conselheiro.
   * @param {number} id Chave primaria.
   * @param {string} nome Nome completo.
   * @param {number} creci Numero de CRECI.
   * @param {boolean} suplente True = suplente. False = Efetivo.
   * @param {0|1|2} federal 0 = nao. 1 = efetivo. 2 = suplente.
   * @param {0|1|2} fiscal 0 = nao. 1 = efetivo. 2 = suplente.
   * @returns {Promise<number>} Retorna o ID.
   */
  async updateConselheiro(id, nome, creci, suplente, federal, fiscal) {
    const formData = new FormData();
    formData.append('id', id.toString());
    formData.append('nome', nome);
    formData.append('creci', creci.toString());
    formData.append('suplente', suplente ? '1' : '0');
    formData.append('federal', federal.toString());
    formData.append('fiscal', fiscal.toString());
    const {data} = await this.http.post('/diretoria/conselheiros', formData);
    return data;
  }

  async deleteConselheiro(id) {
    await this.http.delete('/diretoria/conselheiros', {params: {id: id.toString()}});
  }

  //=== Comissão de trabalho ===//

  /**
   * Obtem a lista de comissões
   * @returns {Promise<any[]>}
   */
  async getComissoes() {
    const {data} = await this.http.get('/diretoria/comissoes');
    return data;
  }

  /**
   * Insere uma nova comissão.
   * @param {string} nome Nome da comissão por extenso.
   * @param {string} abreviado Nome da comissão abreviado.
   * @returns {Promise<number>} Retorna o ID da comissão.
   */
  async insertComissao(nome, abreviado) {
    const formData = new FormData();
    formData.append('nome', nome.trim());
    formData.append('abreviado', abreviado.trim());
    const {data} = await this.http.post('/diretoria/comissoes', formData);
    return data;
  }

  /**
   * Atualiza uma comissão existente.
   * @param {number} id Chave primaria.
   * @param {string} nome Nome da comissão por extenso.
   * @param {string} abreviado Nome da comissão abreviado.
   * @returns {Promise<void>}
   */
  async updateComissao(id, nome, abreviado) {
    const formData = new FormData();
    formData.append('id', id.toString());
    formData.append('nome', nome.trim());
    formData.append('abreviado', abreviado.trim());
    await this.http.post('/diretoria/comissoes', formData);
  }

  /**
   * Deleta uma comissão.
   * @param {number} id Chave primaria.
   * @returns {Promise<void>}
   */
  async deleteComissao(id) {
    await this.http.delete('/diretoria/comissoes', {params: {id: id.toString()}});
  }

  /**
   * Obtem a listagem dos corretores que constam no cadastro das comissões.
   * @returns {Promise<any[]>}
   */
  async getComissaoCorretores() {
    const {data} = await this.http.get('/diretoria/comissoes/corretores');
    return data;
  }

  /**
   * Insere um corretor no cadastro de comissões.
   * @param {string} cpf CPF do corretor.
   * @param {string} nome Nome completo.
   * @param {number} creci Numero de CRECI.
   * @returns {Promise<number>} Retorna o ID da inscricao.
   */
  async insertComissaoCorretor(cpf, nome, creci) {
    const formData = new FormData();
    formData.append('cpf', cpf);
    formData.append('nome', nome);
    formData.append('creci', creci.toString());
    const {data} = await this.http.post('/diretoria/comissoes/corretores', formData);
    return data;
  }

  /**
   * Atualiza um corretor no cadastro de comissões.
   * @param {number} id Chave primaria do cadastro.
   * @param {string} cpf CPF do corretor.
   * @param {string} nome Nome completo.
   * @param {number} creci Numero de CRECI.
   * @returns {Promise<void>} Retorna o ID da inscricao.
   */
  async updateComissaoCorretor(id, cpf, nome, creci) {
    const formData = new FormData();
    formData.append('id', id.toString());
    formData.append('cpf', cpf);
    formData.append('nome', nome);
    formData.append('creci', creci.toString());
    await this.http.post('/diretoria/comissoes/corretores', formData);
  }

  /**
   * Deleta um corretor do cadastro de comissões.
   * @param {number} id Chave primaria do corretor.
   * @returns {Promise<void>}
   */
  async deleteComissaoCorretor(id) {
    await this.http.delete('/diretoria/comissoes/corretores', {params: {id: id.toString()}});
  }

  /**
   * Insere um corretor como membro de uma comissão.
   * @param {number} idComissao Chave primaria da comissao.
   * @param {number} idCorretor Chave primaria do corretor no cadastro de comissoes.
   * @returns {Promise<number>} Retorna o ID da inscricao.
   */
  async insertComissaoMembro(idComissao, idCorretor) {
    const formData = new FormData();
    formData.append('comissao', idComissao.toString());
    formData.append('corretor', idCorretor.toString());
    const {data} = await this.http.put('/diretoria/comissoes/corretores', formData);
    return data;
  }

  /**
   * Remove um corretor de uma comissão.
   * @param {number} idComissao Chave primaria da comissao.
   * @param {number} idCorretor Chave primaria do corretor no cadastro de comissoes.
   * @returns {Promise<void>}
   */
  async deleteComissaoMembro(idComissao, idCorretor) {
    const formData = new FormData();
    formData.append('delete', 'S');
    formData.append('comissao', idComissao.toString());
    formData.append('corretor', idCorretor.toString());
    await this.http.put('/diretoria/comissoes/corretores', formData);
  }
}