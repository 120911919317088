<template>
  <v-container>
    <p class="title mb-0">Diretoria</p>
    <p class="subtitle-1">Gestão atual</p>
    <v-divider class="mb-5"/>
    <v-row class="row-diretores">
      <!-- Presidente -->
      <v-col cols="12">
        <div class="d-flex justify-center">
          <img class="foto elevation-2" src="https://crecims.gov.br/img/diretoria/elirodrigues.jpg" alt="">
          <div class="dados">
            <p class="title mb-0">Presidente</p>
            <p class="subtitle-1 mb-0">Eli Rodrigues</p>
            <v-divider/>
            <p class="subtitle-2">CRECI 1070</p>
          </div>
        </div>
      </v-col>
      <!-- Vice-Presidente -->
      <v-col cols="12" sm="6">
        <div class="d-flex justify-center">
          <img class="foto elevation-2" src="https://crecims.gov.br/img/diretoria/simoneleal.jpg" alt="">
          <div class="dados">
            <p class="title mb-0">Vice-Presidente</p>
            <p class="subtitle-1 mb-0">Simone Ferreira Leal</p>
            <v-divider/>
            <p class="subtitle-2">CRECI 2953</p>
          </div>
        </div>
      </v-col>
      <v-col cols="12" sm="6">
        <div class="d-flex justify-center">
          <img class="foto elevation-2" src="https://crecims.gov.br/img/diretoria/bogaz.jpg" alt="">
          <div class="dados">
            <p class="title mb-0">2º Vice-Presidente</p>
            <p class="subtitle-1 mb-0">Luiz Fernandes Bogaz</p>
            <v-divider/>
            <p class="subtitle-2">CRECI 2294</p>
          </div>
        </div>
      </v-col>
      <!-- Diretor Secretário -->
      <v-col cols="12" sm="6">
        <div class="d-flex justify-center">
          <img class="foto elevation-2" src="https://crecims.gov.br/img/diretoria/james.jpg" alt="">
          <div class="dados">
            <p class="title mb-0">Diretor Secretário</p>
            <p class="subtitle-1 mb-0">James Antônio Gomes</p>
            <v-divider/>
            <p class="subtitle-2">CRECI 2639</p>
          </div>
        </div>
      </v-col>
      <v-col cols="12" sm="6">
        <div class="d-flex justify-center">
          <img class="foto elevation-2" src="https://crecims.gov.br/img/diretoria/robsongomes.jpg" alt="">
          <div class="dados">
            <p class="title mb-0">2º Diretor Secretário</p>
            <p class="subtitle-1 mb-0">Robson dos Santos Gomes</p>
            <v-divider/>
            <p class="subtitle-2">CRECI 8799</p>
          </div>
        </div>
      </v-col>
      <!-- Diretor Tesoureiro -->
      <v-col cols="12" sm="6">
        <div class="d-flex justify-center">
          <img class="foto elevation-2" src="https://crecims.gov.br/img/diretoria/vanderlan.jpg" alt="">
          <div class="dados">
            <p class="title mb-0">Diretor Tesoureiro</p>
            <p class="subtitle-1 mb-0">Vanderlan da Silva Queiroz Júnior</p>
            <v-divider/>
            <p class="subtitle-2">CRECI 2520</p>
          </div>
        </div>
      </v-col>
      <v-col cols="12" sm="6">
        <div class="d-flex justify-center">
          <img class="foto elevation-2" src="https://crecims.gov.br/img/diretoria/kellylima.jpg" alt="">
          <div class="dados">
            <p class="title mb-0">2º Diretor Tesoureiro</p>
            <p class="subtitle-1 mb-0">Kelly Farias Lima</p>
            <v-divider/>
            <p class="subtitle-2">CRECI 3043</p>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Diretores'
}
</script>

<style scoped>
  .foto {
    max-width: 10rem;
    border-radius: .75rem;
  }
  .row-diretores .title {
    line-height: 1;
  }
  .dados {
    margin-left: 1rem;
    text-align: center;
    width: 12.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
</style>