<template>
  <async-container fluid :loading="!loaded">
    <v-data-table
      :headers="[
        {text: 'Nome', value: 'nome'},
        {text: 'CRECI', value: 'creci'},
        {text: 'Comissões', value: 'comissoes', sortable: false},
        {text: 'Ações', value: 'actions', sortable: false},
      ]"
      :items="corretores"
      sort-by="nome"
      class="elevation-2"
      :footer-props="{'items-per-page-options': [10, 25, 50, 100]}"
    >
      <template v-slot:top>
        <v-card-title class="title-bar">
          Corretores
          <v-spacer/>
          <v-btn color="primary" @click="adicionarCorretor">ADICIONAR</v-btn>
        </v-card-title>
      </template>
      <template v-slot:item.comissoes="{item}">
        <v-chip-group column v-if="item.comissoes.length > 0">
          <v-chip small v-for="c in item.comissoes" :key="c.id">{{c.abreviado}}</v-chip>
        </v-chip-group>
      </template>
      <template v-slot:item.actions="{item}">
        <div class="text-no-wrap">
          <v-btn icon x-small color="warning" @click="editarCorretor(item.id)">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn icon x-small color="error" @click="deletarCorretor(item.id)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>
    <v-data-table
      :headers="[
        {text: 'Abreviação', value: 'abreviado'},
        {text: 'Nome', value: 'nome'},
        {text: 'Membros', value: 'membros'},
        {text: 'Ações', value: 'actions', sortable: false},
      ]"
      :items="comissoes"
      sort-by="abreviado"
      class="elevation-2 my-5"
      :footer-props="{'items-per-page-options': [10, 25, 50, 100]}"
    >
      <template v-slot:top>
        <v-card-title class="title-bar">
          Comissões
          <v-spacer/>
          <v-btn color="primary" @click="adicionarComissao">ADICIONAR</v-btn>
        </v-card-title>
      </template>
      <template v-slot:item.actions="{item}">
        <div class="text-no-wrap">
          <v-btn icon x-small color="warning" @click="editarComissao(item.id)">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn icon x-small color="error" @click="deletarComissao(item.id)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>
    <v-dialog v-model="dialogAddCorretor" max-width="500">
      <v-card>
        <v-card-title>{{deleteId ? 'Editar' : 'Adicionar'}} corretor</v-card-title>
        <v-card-text>
          <v-form ref="form-addcorretor" :disabled="addingCorretor">
            <v-text-field
              label="Número de CRECI"
              v-model="iptCreci"
              :rules="[rules.obrigatorio, rules.numerico, ruleCreciDuplicated]"
              :disabled="!!deleteId"
            ></v-text-field>
            <v-combobox
              label="Comissões"
              v-model="iptComissoes"
              multiple
              :items="comissoes"
              item-text="abreviado"
              small-chips
              deletable-chips
              :rules="[rules.obrigatorio]"
            ></v-combobox>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn color="primary" text @click="dialogAddCorretor = false" :disabled="addingCorretor">Cancelar</v-btn>
          <v-btn color="primary" text @click="insertCorretor" :loading="addingCorretor">Gravar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDeleteCorretor" max-width="500">
      <v-card>
        <v-card-title style="background-color: #f5f5f5; border-bottom: 1px solid #dbdbdb">Excluir conselheiro</v-card-title>
        <v-card-text class="mt-3">
          <div class="d-flex align-center">
            <v-icon size="48" class="mr-3" color="error">mdi-alert</v-icon>
            <p class="body-1 flex-grow-1 mb-0">Você está prestes a remover:<br/><span class="font-weight-bold">"{{deleteName}}"</span></p>
          </div>
        </v-card-text>
        <v-card-actions style="background-color: #f5f5f5; border-top: 1px solid #dbdbdb">
          <v-spacer></v-spacer>
          <v-btn color="secondary" @click="dialogDeleteCorretor = false" :disabled="deletingCorretor">CANCELAR</v-btn>
          <v-btn color="error" @click="deleteCorretor" :loading="deletingCorretor">CONFIRMAR</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogAddComissao" max-width="500">
      <v-card>
        <v-card-title>Adicionar comissão</v-card-title>
        <v-card-text>
          <v-form ref="form-addcomissao" :disabled="addingComissao">
            <v-text-field
              label="Nome da comissão por extenso"
              v-model="iptComissaoNome"
              :rules="[rules.obrigatorio]"
            ></v-text-field>
            <v-text-field
              label="Nome da comissão abreviado"
              v-model="iptComissaoAbreviado"
              :rules="[rules.obrigatorio]"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn color="primary" text @click="dialogAddComissao = false" :disabled="addingComissao">Cancelar</v-btn>
          <v-btn color="primary" text @click="insertComissao" :loading="addingComissao">Gravar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDeleteComissao" max-width="500">
      <v-card>
        <v-card-title style="background-color: #f5f5f5; border-bottom: 1px solid #dbdbdb">Excluir comissão</v-card-title>
        <v-card-text class="mt-3">
          <div class="d-flex align-center">
            <v-icon size="48" class="mr-3" color="error">mdi-alert</v-icon>
            <p class="body-1 flex-grow-1 mb-0">Você está prestes a remover a comissão:<br/><span class="font-weight-bold">"{{deleteName}}"</span></p>
          </div>
        </v-card-text>
        <v-card-actions style="background-color: #f5f5f5; border-top: 1px solid #dbdbdb">
          <v-spacer></v-spacer>
          <v-btn color="secondary" @click="dialogDeleteComissao = false" :disabled="deletingComissao">CANCELAR</v-btn>
          <v-btn color="error" @click="deleteComissao" :loading="deletingComissao">CONFIRMAR</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </async-container>
</template>

<script>
import AsyncContainer from '@/components/interface/AsyncContainer';
import DiretoriaWebClient from '@/http/DiretoriaWebClient';
import StringHelper from '@/helpers/StringHelper';
import I2brWebClient from '@/http/I2brWebClient';
export default {
  name: 'Comissoes',
  components: {AsyncContainer},
  data: () => ({
    loaded: false,
    webClient: new DiretoriaWebClient(),
    comissoes: [],
    corretores: [],
    dialogAddCorretor: false,
    dialogDeleteCorretor: false,
    dialogAddComissao: false,
    dialogDeleteComissao: false,
    iptCreci: '',
    iptComissoes: [],
    addingCorretor: false,
    deletingCorretor: false,
    rules: {
      obrigatorio: (v) => (!!v && (typeof v === 'string' || typeof v === 'boolean')) || v === 0 || (Array.isArray(v) && v.length > 0) || 'Este campo é obrigatório. Faça o preenchimento.',
      numerico: v => (v && StringHelper.extractNumbers(v).length === v.trim().length) || 'Insira apenas números.',
    },
    deleteId: null,
    deleteName: '',
    addingComissao: false,
    deletingComissao: false,
    iptComissaoNome: '',
    iptComissaoAbreviado: '',
  }),
  methods: {
    ruleCreciDuplicated(v) {
      if (this.deleteId) return true;
      const corretor = this.corretores.find(i => i.creci.toString() === v);
      return !corretor || 'Este CRECI já está na lista';
    },
    async loadData() {
      try {
        this.comissoes = await this.webClient.getComissoes();
        this.corretores = await this.webClient.getComissaoCorretores();
      } catch (e) {
        this.$router.push('/diretoria');
      } finally {
        this.loaded = true;
      }
    },

    adicionarCorretor() {
      this.deleteId = null;
      this.iptCreci = '';
      this.iptComissoes = [];
      if (this.$refs['form-addcorretor']) this.$refs['form-addcorretor'].resetValidation();
      this.dialogAddCorretor = true;
    },
    editarCorretor(corretorId) {
      const corretor = this.corretores.find(i => i.id === corretorId);
      if (!corretor) return;
      const comissoesId = corretor.comissoes.map(c => c.id);
      this.deleteId = corretorId;
      this.iptCreci = corretor.creci.toString();
      this.iptComissoes = this.comissoes.filter(c => comissoesId.includes(c.id));
      if (this.$refs['form-addcorretor']) this.$refs['form-addcorretor'].resetValidation();
      this.dialogAddCorretor = true;
    },
    deletarCorretor(corretorId) {
      const corretor = this.corretores.find(i => i.id === corretorId);
      if (!corretor) return;
      this.deleteId = corretorId;
      this.deleteName = corretor.nome;
      this.dialogDeleteCorretor = true;
    },

    adicionarComissao() {
      this.deleteId = null;
      this.iptComissaoNome = '';
      this.iptComissaoAbreviado = '';
      if (this.$refs['form-addcomissao']) this.$refs['form-addcomissao'].resetValidation();
      this.dialogAddComissao = true;
    },
    editarComissao(comissaoId) {
      const comissao = this.comissoes.find(i => i.id === comissaoId);
      if (!comissao) return;
      this.deleteId = comissao.id;
      this.iptComissaoNome = comissao.nome;
      this.iptComissaoAbreviado = comissao.abreviado;
      if (this.$refs['form-addcomissao']) this.$refs['form-addcomissao'].resetValidation();
      this.dialogAddComissao = true;
    },
    deletarComissao(comissaoId) {
      const comissao = this.comissoes.find(i => i.id === comissaoId);
      if (!comissao) return;
      this.deleteId = comissao.id;
      this.deleteName = comissao.nome;
      this.dialogDeleteComissao = true;
    },

    async insertCorretor() {
      if (!this.$refs['form-addcorretor'].validate()) return;
      if (this.deleteId) {
        await this.updateCorretor();
        return;
      }
      this.addingCorretor = true;
      const i2brWebClient = new I2brWebClient();
      const result = await i2brWebClient.buscarCreci(this.iptCreci);
      const corretor = result.find(i => i.tipo === 1);
      if (!corretor) {
        this.$alert({text: 'Não foi possível encontrar o corretor pelo ConselhoNET', type: 'error'});
        return;
      }
      try {
        const corretorId = await this.webClient.insertComissaoCorretor(corretor.cpf, corretor.nome, this.iptCreci);
        for (const i of this.iptComissoes) {
          await this.webClient.insertComissaoMembro(i.id, corretorId);
        }
        await this.loadData();
        this.dialogAddCorretor = false;
      } finally {
        this.addingCorretor = false;
      }
    },
    async updateCorretor() {
      const corretor = this.corretores.find(i => i.id === this.deleteId);
      if (!corretor) return;
      const comissoesQueFazParte = corretor.comissoes.map(i => i.id);
      const comissoesSelecionadas = this.iptComissoes.map(i => i.id);
      const deixaraDeFazerParte = comissoesQueFazParte.filter(i => !comissoesSelecionadas.includes(i));
      const comecaraFazerParte = comissoesSelecionadas.filter(i => !comissoesQueFazParte.includes(i));
      if (deixaraDeFazerParte.length > 0 || comecaraFazerParte.length > 0) {
        try {
          this.addingCorretor = true;
          if (deixaraDeFazerParte.length > 0) {
            for (const i of deixaraDeFazerParte) {
              await this.webClient.deleteComissaoMembro(i, this.deleteId);
            }
          }
          if (comecaraFazerParte.length > 0) {
            for (const i of comecaraFazerParte) {
              await this.webClient.insertComissaoMembro(i, this.deleteId);
            }
          }
          await this.loadData();
        } finally {
          this.addingCorretor = false;
        }
      }
      this.dialogAddCorretor = false;
    },
    async deleteCorretor() {
      this.deletingCorretor = true;
      try {
        await this.webClient.deleteComissaoCorretor(this.deleteId);
        await this.loadData();
        this.dialogDeleteCorretor = false;
        this.deleteId = null;
        this.deleteName = '';
      } finally {
        this.deletingCorretor = false;
      }
    },

    async insertComissao() {
      if (!this.$refs['form-addcomissao'].validate()) return;
      if (this.deleteId) {
        await this.updateComissao();
        return;
      }
      this.addingComissao = true;
      try {
        await this.webClient.insertComissao(this.iptComissaoNome, this.iptComissaoAbreviado);
        await this.loadData();
        this.dialogAddComissao = false;
      } finally {
        this.addingComissao = false;
      }
    },
    async updateComissao() {
      this.addingComissao = true;
      try {
        await this.webClient.updateComissao(this.deleteId, this.iptComissaoNome, this.iptComissaoAbreviado);
        await this.loadData();
        this.dialogAddComissao = false;
      } finally {
        this.addingComissao = false;
      }
    },
    async deleteComissao() {
      this.deletingComissao = true;
      try {
        await this.webClient.deleteComissao(this.deleteId);
        await this.loadData();
        this.dialogDeleteComissao = false;
        this.deleteId = null;
        this.deleteName = '';
      } finally {
        this.deletingComissao = false;
      }
    },
  },
  created() {
    this.loadData();
  },
}
</script>

<style scoped>
  .title-bar {
    background-color: #f5f5f5;
    border-bottom: 1px solid #dbdbdb !important;
    padding: .75rem;
    margin-bottom: 0;
  }
</style>