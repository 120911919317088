<template>
  <async-container :loading="!loaded">
    <v-data-table
      class="elevation-2"
      :headers="headers"
      :items="itemsFiltered"
      :search="search"
      sort-by="nome"
      :footer-props="{'items-per-page-options': [10, 25, 50, 100]}"
    >
      <template v-slot:top>
        <v-card-title class="title-bar">
          Conselheiros
          <v-spacer/>
          <v-btn color="primary" @click="adicionarConselheiro">ADICIONAR</v-btn>
        </v-card-title>
        <v-card-text class="pb-0">
          <v-text-field
            class="pt-0 mt-3"
            v-model="search"
            prepend-inner-icon="mdi-magnify"
            label="Busque por nome ou CRECI"
            single-line
            hide-details
            outlined
            dense
          ></v-text-field>
        </v-card-text>
      </template>
      <template v-slot:item.suplente="{item}">
        <v-chip v-if="item.suplente" small outlined>SUPLENTE</v-chip>
        <v-chip v-else color="primary" small outlined>EFETIVO</v-chip>
      </template>
      <template v-slot:item.functions="{item}">
        <v-chip-group column>
          <v-chip color="warning" label small v-if="item.federal === 1">FEDERAL</v-chip>
          <v-chip color="warning" label small v-else-if="item.federal === 2">FEDERAL SUPLENTE</v-chip>
          <v-chip color="success" label small v-if="item.fiscal === 1">CONSELHO FISCAL</v-chip>
          <v-chip color="success" label small v-else-if="item.fiscal === 2">CONSELHO FISCAL SUPLENTE</v-chip>
        </v-chip-group>
      </template>
      <template v-slot:item.actions="{item}">
        <div class="text-no-wrap">
          <v-btn icon x-small color="warning" @click="editarConselheiro(item.id)">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn icon x-small color="error" @click="deletarConselheiro(item.id)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>
    <v-dialog v-model="showDialogAdd" max-width="500">
      <v-card>
        <v-card-title>{{iptId ? 'Editar' : 'Adicionar'}} conselheiro</v-card-title>
        <v-card-text>
          <v-form v-model="formValid" :disabled="loadingAdd">
            <v-text-field
              label="Número de CRECI"
              v-model="iptCreci"
              :rules="[rules.obrigatorio, rules.numerico, ruleDuplicated]"
              :disabled="!!iptId"
            ></v-text-field>
            <v-checkbox
              label="Suplente"
              v-model="iptSuplente"
              hide-details
              class="mt-0"
            ></v-checkbox>
            <v-divider class="my-4"/>
            <p class="body-1">Funções extras</p>
            <p class="mb-0">Conselheiro Federal</p>
            <v-btn-toggle v-model="iptFederal">
              <v-btn small>Não</v-btn>
              <v-btn small>Efetivo</v-btn>
              <v-btn small>Suplente</v-btn>
            </v-btn-toggle>
            <p class="mb-0 mt-3">Conselho Fiscal</p>
            <v-btn-toggle v-model="iptFiscal">
              <v-btn small>Não</v-btn>
              <v-btn small>Efetivo</v-btn>
              <v-btn small>Suplente</v-btn>
            </v-btn-toggle>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn text color="primary" @click="showDialogAdd = false" :disabled="loadingAdd">Cancelar</v-btn>
          <v-btn text color="primary" @click="salvarConselheiro" :disabled="!formValid" :loading="loadingAdd">Salvar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showDeleteDialog" max-width="500">
      <v-card>
        <v-card-title style="background-color: #f5f5f5; border-bottom: 1px solid #dbdbdb">Excluir conselheiro</v-card-title>
        <v-card-text class="mt-3">
          <div class="d-flex align-center">
            <v-icon size="48" class="mr-3" color="error">mdi-alert</v-icon>
            <p class="body-1 flex-grow-1 mb-0">Você está prestes a remover:<br/><span class="font-weight-bold">"{{deleteName}}"</span></p>
          </div>
        </v-card-text>
        <v-card-actions style="background-color: #f5f5f5; border-top: 1px solid #dbdbdb">
          <v-spacer></v-spacer>
          <v-btn color="secondary" @click="showDeleteDialog = false" :disabled="deleting">CANCELAR</v-btn>
          <v-btn color="error" @click="deletarConselheiro()" :loading="deleting">CONFIRMAR</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </async-container>
</template>

<script>
import AsyncContainer from '@/components/interface/AsyncContainer';
import StringHelper from '@/helpers/StringHelper';
import DiretoriaWebClient from '@/http/DiretoriaWebClient';
import I2brWebClient from '@/http/I2brWebClient';
export default {
  name: 'Conselheiros',
  components: {AsyncContainer},
  data: () => ({
    webClient: new DiretoriaWebClient(),
    loaded: false,
    rules: {
      obrigatorio: v => !!v || 'Este dado é obrigatório',
      numerico: v => (v && StringHelper.extractNumbers(v).length === v.trim().length) || 'Insira apenas números',
    },
    headers: [
      {text: 'Nome', value: 'nome'},
      {text: 'CRECI', value: 'creci'},
      {text: 'Categoria', value: 'suplente', align: 'center'},
      {text: 'Funções', value: 'functions'},
      {text: 'Ações', value: 'actions', align: 'center', filterable: false, sortable: false},
    ],
    items: [],
    search: '',
    showDialogAdd: false,
    iptId: null,
    iptCreci: '',
    iptSuplente: false,
    iptFederal: 0,
    iptFiscal: 0,
    formValid: false,
    loadingAdd: false,
    showDeleteDialog: false,
    deleting: false,
    deleteName: '',
    deleteId: null,
  }),
  methods: {
    async loadData() {
      try {
        this.items = await this.webClient.getConselheiros();
      } catch (e) {
        this.$router.push('/diretoria');
      } finally {
        this.loaded = true;
      }
    },
    limparCampos() {
      this.iptId = null;
      this.iptCreci = '';
      this.iptSuplente = false;
      this.iptFederal = 0;
      this.iptFiscal = 0;
    },
    preencherCampos(id) {
      const conselheiro = this.items.find(i => i.id === id);
      this.iptId = conselheiro.id;
      this.iptCreci = conselheiro.creci.toString();
      this.iptSuplente = conselheiro.suplente;
      this.iptFederal = conselheiro.federal;
      this.iptFiscal = conselheiro.fiscal;
    },
    adicionarConselheiro() {
      this.limparCampos();
      this.showDialogAdd = true;
    },
    editarConselheiro(id) {
      this.preencherCampos(id);
      this.showDialogAdd = true;
    },
    async deletarConselheiro(id) {
      if (!id && this.deleteId) {
        this.deleting = true;
        try {
          await this.webClient.deleteConselheiro(this.deleteId);
          await this.loadData();
        } finally {
          this.deleting = false;
          this.showDeleteDialog = false;
        }
      } else {
        const conselheiro = this.items.find(i => i.id === id);
        if (!conselheiro) return;
        this.deleteId = id;
        this.deleteName = conselheiro.nome;
        this.showDeleteDialog = true;
      }
    },
    ruleDuplicated(v) {
      if (this.iptId) return true;
      const creci = v ? parseInt(v) : 0;
      return !this.items.find(i => i.creci === creci) || 'Este CRECI já está na lista';
    },
    async salvarConselheiro() {
      if (!this.formValid) return;
      this.loadingAdd = true;
      try {
        const i2brWebClient = new I2brWebClient();
        const result = await i2brWebClient.buscarCreci(this.iptCreci);
        const corretor = result.find(i => i.tipo === 1);
        if (!corretor) {
          this.$alert({text: 'Não foi possível encontrar o corretor pelo ConselhoNET', type: 'error'});
          return;
        }
        if (this.iptId) {
          await this.webClient.updateConselheiro(this.iptId, corretor.nome, this.iptCreci, this.iptSuplente, this.iptFederal, this.iptFiscal);
          this.$snackbar({text: 'Conselheiro atualizado', color: 'success'});
        } else {
          await this.webClient.insertConselheiro(corretor.nome, this.iptCreci, this.iptSuplente, this.iptFederal, this.iptFiscal);
          this.$snackbar({text: `Conselheiro inserido: ${corretor.nome}`, color: 'success'});
        }
        await this.loadData();
        this.showDialogAdd = false;
      } finally {
        this.loadingAdd = false;
      }
    },
  },
  computed: {
    itemsFiltered() {
      return this.items;
    },
  },
  created() {
    this.loadData();
  }
}
</script>

<style scoped>
  .title-bar {
    background-color: #f5f5f5;
    border-bottom: 1px solid #dbdbdb !important;
    padding: .75rem;
    margin-bottom: 0;
  }
</style>
